import { autoinject, bindable, bindingMode, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { MyHttpApi, PrivilegeItem } from 'utils/api';
import { setCorporation } from 'utils/corporation-util';

let idGen = 0;

interface UIBusinessGroupPrivilegeItem extends PrivilegeItem {
  clientVisible: boolean;
  clients: PrivilegeItem[];
}

interface UICorporationPrivilegeItem extends PrivilegeItem {
  businessGroupVisible: boolean;
  businessGroups: UIBusinessGroupPrivilegeItem[];
}

@autoinject
export class CorporationSelectCustomElement {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) value?: PrivilegeItem = undefined;
  @bindable({ defaultBindingMode: bindingMode.toView }) disabled?: boolean;
  @bindable({ defaultBindingMode: bindingMode.toView }) required?: boolean;
  @bindable({ defaultBindingMode: bindingMode.toView }) hideCorporations?: boolean;

  private readonly id = "corporation-select-" + ++idGen;
  private content?: PrivilegeItem[] = undefined;
  private search = "";
  private searchMode = false;
  private clientList: PrivilegeItem[] = [];
  private businessGroupList: UIBusinessGroupPrivilegeItem[] = [];
  private corporationList: UICorporationPrivilegeItem[] = [];

  constructor(private readonly api: MyHttpApi, private readonly i18n: I18N, private readonly element: Element) {
  }

  detached() {
    document.removeEventListener("click", this.clickHandler);
  }

  async attached() {
    this.content = (await this.api.privilegeListAll()).filter(p => !this.hideCorporations || p.type != "CORPORATION");
    if (this.hideCorporations && this.value?.type == "CORPORATION") {
        this.value = undefined;
    }
    if (!this.value && this.content.length) {
      this.select(this.content[0]);
    }
    document.addEventListener("click", this.clickHandler.bind(this));
  }

	clickHandler(e: Event) {
		if(!(this.element.compareDocumentPosition(e.target as Node) &
				Node.DOCUMENT_POSITION_CONTAINED_BY)) {
			this.searchMode = false;
		}
	}

  /** If we have only one (even partial) match, select it. */
  async focusEnd() {
    if (!this.content) {
      return;
    }
    let exactMatch = this.content.find(c => c.name.toLowerCase() === this.search.toLowerCase());
    let results = this.content.filter(c => c.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1);
    if (exactMatch) {
      this.select(exactMatch);
    } else if (results.length === 1) {
      this.select(results[0]);
    }
  }

  focusStart() {
    if (this.content?.length && !this.disabled) {
      this.searchMode = true;
    }
  }

  select(item: PrivilegeItem) {
    this.value = item;
    setCorporation(item);
    if (this.value) {
      this.toggleSearch();
    }
  }

  toggleSearch() {
    if (this.disabled) {
      return;
    }
    if (this.value || !this.required) {
      this.searchMode = !this.searchMode;
      this.search = "";
    }
  }

  clear() {
    if (this.disabled) {
      return;
    }
    this.value = undefined;
    this.search = "";
    this.searchMode = false;
  }

  @computedFrom("content", "search")
  get searchObserver() {
    let s = this.search.toLowerCase();
    if (!this.content) {
      return "";
    }
    const doSearch = (s != undefined && s !== "");
    let clientList = this.content?.filter(c => c.type === 'CLIENT');
    clientList = clientList.filter(c => !doSearch || c.name.toLowerCase().indexOf(s) !== -1);

    let businessGroupList: UIBusinessGroupPrivilegeItem[] = this.content
      .filter(bg => bg.type === 'BUSINESS_GROUP')
      .map(bg => {
        const clients = clientList.filter(c => c.businessGroupId === bg.id);
        return {
          ...bg,
          clientVisible: clients.length > 0,
          clients
        };
      })
      .filter(bg => !doSearch || bg.clientVisible || bg.name.toLowerCase().indexOf(s) !== -1);
    
      let corporationList : UICorporationPrivilegeItem[] = this.content
        .filter(co => co.type === 'CORPORATION')
        .map(co => {
          const businessGroups = businessGroupList.filter(bg => bg.corporationId === co.id);
          let item: UICorporationPrivilegeItem = {
            ...co,
            businessGroups,
            businessGroupVisible: businessGroups.length > 0,
          };
          return item;
        })
        .filter(co => !doSearch || co.businessGroupVisible || co.name.toLowerCase().indexOf(s) !== -1);

    this.corporationList = corporationList;

    const seenCorporations = this.corporationList.map(co => co.id);
    this.businessGroupList = businessGroupList.filter(bg => !bg.corporationId || !seenCorporations.includes(bg.corporationId));
    
    const seenBusinessGroups = this.businessGroupList.map(bg => bg.id);
    this.clientList = clientList
    .filter(c => !c.businessGroupId || !seenBusinessGroups.includes(c.businessGroupId))
    .filter(c => !c.corporationId || !seenCorporations.includes(c.corporationId));

    return "";
  }

  @computedFrom("value.id", "value.type", "content", "required")
  get typeAndName() {
    if (!this.content || this.value == undefined) {
      return "";
    }
    let found = this.content.find(c => c.id === this.value?.id && c.type === this.value.type);
    // * If we have bad localStorage-value, try to select any value, that we actually have
    if (this.content.length && !found) {
      this.select(this.content[0]);
      this.searchMode = false;
      found = this.content.find(c => c.id === this.value?.id && c.type === this.value.type);
    }
    if (this.required) {
      let warning = "";
      let el = this.element.getElementsByTagName("input")[0];
      if (!found) {
        warning = this.i18n.tr("common.required");
      }
      if (el) {
        el.setCustomValidity(warning);
        el.reportValidity();
      }
    }
    if (!found) {
      return this.i18n.tr("common.empty");
    }
    return this.i18n.tr("PrivilegeType." + found.type) + ": " + found.name;
  }
}
