import { autoinject, bindable, BindingEngine, bindingMode, computedFrom, Disposable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { BindingSignaler } from 'aurelia-templating-resources';

/** Tagged type union. Field 'type' specifies subtype. */
export type FieldSpec = TextFieldSpec | NumberFieldSpec | DateFieldSpec | EnumFieldSpec | BooleanFieldSpec | LookupFieldSpec | JsonFieldSpec;

export type SelectSpec = { [key: string]: boolean; };

interface GenericFieldSpec {
  type: string;
  header?: string;
  key: string;
  // deprecated: convert field to actual number
  sortType?: "default" | "number";
  cssClass?: string;
}

interface TextFieldSpec extends GenericFieldSpec {
  type: "text";
}

interface NumberFieldSpec extends GenericFieldSpec {
  type: "number";
  minimumFractionDigits?: number;
}

interface DateFieldSpec extends GenericFieldSpec {
  type: "date";
  format?: "DD.MM.YYYY" | "DD.MM.YYYY HH:MM";
}

interface EnumFieldSpec extends GenericFieldSpec {
  type: "enum";
  enum: string;
}

interface BooleanFieldSpec extends GenericFieldSpec {
  type: "boolean";
}

interface LookupFieldSpec extends GenericFieldSpec {
  type: "lookup";
  lookupData: { [key: string | number]: { [key: string]: any; }; };
  lookupKey: string;
}

interface JsonFieldSpec extends GenericFieldSpec {
  type: "json";
}

interface Row {
  row: { [key: string]: any; };
  values: { [key: string]: string; };
}

let tableIdx = 0;

@autoinject
export class BelAuHtmlTableCustomElement {
  @bindable({ defaultBindingMode: bindingMode.toView }) select?: SelectSpec;
  @bindable({ defaultBindingMode: bindingMode.toView }) content: any[] = [];
  @bindable({ defaultBindingMode: bindingMode.twoWay }) searchParams: { [key: string]: string; } = {};
  @bindable({ defaultBindingMode: bindingMode.toView }) fields: FieldSpec[] = []; /* because we don't recompute bindingengine events */
  @bindable({ defaultBindingMode: bindingMode.toView }) delete?: (arg: { value: any; }) => void;
  @bindable({ defaultBindingMode: bindingMode.toView }) row?: (arg: { key: string, value: any; }) => void;
  @bindable({ defaultBindingMode: bindingMode.toView }) sort = "";
  @bindable({ defaultBindingMode: bindingMode.toView }) headerDelete = "";
  @bindable({ defaultBidningMode: bindingMode.toView }) reverse = false;
  @bindable({ defaultBidningMode: bindingMode.toView }) filter = true;
  @bindable({ defaultBidningMode: bindingMode.toView }) perPage = 100;
  @bindable({ defaultBidningMode: bindingMode.toView }) sums = false; /* display numeric fields sums in header */
  @bindable({ defaultBindingMode: bindingMode.fromView }) filteredContent: any[] = []; /* Callback to get only filtered content */

  private Math = Math;

  private signalName = "html-table-" + ++tableIdx;

  private searchObserver: Disposable[] = [];
  private selectAll = false;
  private page = 1;
  private contentCache: any[] = [];
  private convertedContentCache: any[] = [];
  private convertedContentSorted = false;
  private sumsContainer: { [key: string]: number; } = {};

  constructor(private readonly engine: BindingEngine, private readonly signaler: BindingSignaler, private readonly i18n: I18N) {
  }

  attached() {
    /* We need to augment the @computedFrom at rows, but the list is not known ahead of time.
    * Here's what we do. We generate globally unique signal names, one per html table instance.
    * When searchParams[key] changes, the propertyObserver fires, and triggers the signal.
    * The rows rendering listens to this signal and re-renders. */
    for (const x of this.fields) {
      if (!this.searchParams[x.key]) {
        this.searchParams[x.key] = "";
      }
      if (!x.header) {
        x.header = x.key;
      }
      x.cssClass = ["clickable", x.type, x.cssClass].filter(v => v).join(" ");

      const disposable = this.engine.propertyObserver(this.searchParams, x.key).subscribe(() => {
        this.signaler.signal(this.signalName);
        this.page = 1;
      });
      this.searchObserver.push(disposable);
    }
    if (!this.sort) {
      this.setSort(this.fields[0].key);
    }
  }

  detached() {
    for (const x of this.searchObserver) {
      x.dispose();
    }
  }

  get countSelected() {
    if (!this.select) {
      return 0;
    }
    let count = 0;
    for (let x of this.content) {
      if (this.select[x.id]) {
        count++;
      }
    }
    return count;
  }

  toggleSelected(checked: boolean, manualClick: boolean) {

    this.selectAll = checked;
    if (!this.select) {
      return;
    }

    // select all if manually checked and unfiltered
    if (checked && manualClick && this.filteredContent.length == this.content.length) {
      for (let x of this.content) {
        this.select[x.id] = true;
      }
      return;
    }

    // count previously selected
    let selectedCount = 0;
    for (let x of this.content) {
      if (this.select[x.id]) {
        selectedCount++;
      }
    }

    // deselect all if unchecked and all filtered content is selected
    if (!checked && this.filteredContent.length == selectedCount) {
      for (let x of this.content) {
        this.select[x.id] = false;
      }
      return;
    }

    for (let x of this.content) {
      // reduce selected by intersetion with filtered or select all filtered if manually checked
      this.select[x.id] = ((manualClick && checked) || this.select[x.id]) && !!this.filteredContent.find(f => f.row.id == x.id);
    }

    let allFalse = true;

    for (let x in this.select) {
      allFalse = allFalse && !this.select[x];
    }

    if (allFalse) {
      this.selectAll = false;
    }
  }

  get selectSome() {
    if (!this.select) {
      return false;
    }

    let selectedCount = 0;
    for (let x of this.content) {
      if (this.select[x.id]) {
        selectedCount++;
      }
    }
    return selectedCount > 0 && this.filteredContent.length != selectedCount;
  }

  @computedFrom("content")
  get convertedContent() {
    if (this.content === this.contentCache) {
      return this.convertedContentCache;
    }
    this.convertedContentSorted = false;
    this.contentCache = this.content;
    this.convertedContentCache = this.contentCache.map(x => ({ row: x, values: {} }));
    return this.convertedContentCache;
  }

  convertOne(field: FieldSpec, value: any) {
    if (value == undefined) {
      return "";
    }

    switch (field.type) {
      case 'boolean':
        return value ? "✓" : " ";
      case 'date': {
        let dt = <Date>value;
        if (field.format === "DD.MM.YYYY") {
          return dt.toLocaleDateString("fi-FI");
        } else {
          return dt.toLocaleDateString("fi-FI") + " " + dt.toLocaleTimeString("fi-FI");
        }
      }
      case 'enum':
        return this.i18n.tr(field.enum + "." + value, {});
      case 'json':
        return JSON.stringify(value);
      case 'lookup': {
        let lookup = field.lookupData && field.lookupData[value];
        let lookupKey = field.lookupKey || "name";
        return lookup != undefined ? lookup[lookupKey] : value;
      }
      case 'number':
        return (<number>value).toLocaleString("fi-FI", { minimumFractionDigits: field.minimumFractionDigits });
      case 'text':
        return typeof value == 'string' ? value : "" + value;
    }
  }

  /**
  * Convert specific fields of that row to string
  * 
  * @param row 
  * @param fields 
  */
  convertRow(row: Row, fields: FieldSpec[]) {
    for (const field of fields) {
      if (field.key in row.values) {
        continue;
      }

      const value = row.row[field.key];
      let converted: string;
      if (value instanceof Array) {
        converted = value.map(v => this.convertOne(field, v)).join(", ");
      } else {
        converted = this.convertOne(field, value);
      }

      row.values[field.key] = converted;
    }
  }

  @computedFrom("filteredContent", "perPage")
  get maxPage() {
    return Math.floor((this.filteredContent.length - 1) / this.perPage) + 1;
  }

  @computedFrom("page", "maxPage", "filteredContent")
  get paging() {
    const array: number[] = [];
    for (let i = Math.max(1, this.page - 5); i <= Math.min(this.maxPage, this.page + 5); i++) {
      array.push(i);
    }
    return array;
  }

  computeSumsContainer() {
    let _sumsContainer: { [key: string]: number; } = {};
    if (!this.sums) {
      return _sumsContainer;
    }
    let fieldsToRound: FieldSpec[] = [];
    for (const x of this.filteredContent) {
      for (const field of this.fields) {
        const keyName = field.key;
        if (field.type === "number") {
          if (field.minimumFractionDigits && !fieldsToRound.includes(field)) {
            fieldsToRound.push(field);
          }
          _sumsContainer[keyName] = (_sumsContainer[keyName] || 0) + x.row[keyName];
        }
        if (field.type === "boolean" && x.row[keyName]) {
          _sumsContainer[keyName] = (_sumsContainer[keyName] || 0) + 1;
        }
      }
    }
    for (const field of fieldsToRound) {
      if (field.type == "number") {
        _sumsContainer[field.key] = parseFloat(_sumsContainer[field.key].toFixed(field.minimumFractionDigits));
      }
    }
    return _sumsContainer;
  }

  @computedFrom("convertedContent", "convertedContentSorted", "sort", "reverse", "page", "perPage")
  get rows() {
    let sortType = this.fields.find(f => f.key == this.sort)?.sortType;
    if (!this.convertedContentSorted) {
      /* Use objects for sorting */
      this.convertedContent.sort((ca, cb) => {
        let a = ca.row[this.sort];
        let b = cb.row[this.sort];
        let r;

        if (sortType == 'number') {
          // convert possible undefined/null values to string
          a = String(a);
          b = String(b);
          // replace all non-digits with ''
          a = a.replace(/\D/g, '');
          b = b.replace(/\D/g, '');
        }

        if (a == undefined && b != undefined) {
          r = -1;
        } else if (a != undefined && b == undefined) {
          r = 1;
        } else if (a == undefined && b == undefined) {
          r = 0;
        } else if (a < b) {
          r = -1;
        } else if (a > b) {
          r = 1;
        } else {
          r = 0;
        }
        return this.reverse ? -r : r;
      });
      this.convertedContentSorted = true;
    }

    /* Use converted values for filtering */
    let content = this.convertedContent;

    /* Note: search is observed via template binding. */
    for (let key in this.searchParams) {
      let v = this.searchParams[key];
      if (!v) {
        continue;
      }
      let fields = this.fields.filter(x => x.key === key);
      let doExactComparison = fields.length && fields[0].type == 'enum';
      content = content.filter(x => {
        if (doExactComparison) {
          return x.row[key] == v;
        }
        this.convertRow(x, fields);
        return x.values[key].toLowerCase().indexOf(v.toLowerCase()) !== -1;
      });
    }
    /* Echo filtered content for length purposes*/
    this.filteredContent = content;

    /* Apply content paging */
    content = content.slice((this.page - 1) * this.perPage, this.page * this.perPage);
    for (let x of content) {
      this.convertRow(x, this.fields);
    }

    /* recalculate selected after after content was filtered */
    this.toggleSelected(this.selectAll, false);

    this.sumsContainer = this.computeSumsContainer();
    return content;
  }

  rowClicked(key: string, row: any) {
    this.row && this.row({
      key: key,
      value: row,
    });
  }

  deleteClicked(row: any) {
    this.delete && this.delete({
      value: row,
    });
  }

  setSort(field: any) {
    let sort = field.key;
    if (sort === this.sort) {
      this.reverse = !this.reverse;
    } else {
      this.sort = sort;
      this.reverse = false;
      this.page = 1;
    }
    this.convertedContentSorted = false;
    this.signaler.signal(this.signalName);
  }

  setPage(page: number) {
    this.page = Math.max(1, Math.min(page, this.maxPage));
  }

  enumKeys(enumName: string) {
    // @ts-ignore
    let enumTranslations = this.i18n.i18next.getDataByLanguage(this.i18n.getLocale()).translation[enumName];
    if (enumTranslations == null) {
      console.warn(`No translations found for ${enumName}`);
      return;
    }
    return Object.keys(enumTranslations);
  }
}
